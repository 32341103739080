import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import "../components/index/styles.scss"
import SimpleLocalize from "../components/SimpleLocalize";
import Section from "../components/common/Section";
import { Helmet } from "react-helmet"
import DesktopVideoHeader from "../components/index/desktop/VideoHeader";
import MobileVideoHeader from "../components/index/mobile/VideoHeader";
import DesktopSection2 from "../components/index/desktop/Section2";
import MobileSection2 from '../components/index/mobile/Section2';
import DesktopSection3 from '../components/index/desktop/Section3';
import MobileSection3 from '../components/index/mobile/Section3';
import Video360 from "../components/index/mobile/Video360";

const MobilePage = (props) => {
	let camino = '..'
	//let poslang = '';
	if (props.pageContext.language === 'en') {
		camino = '../..';
		//poslang = "_en";
	}

	return (
	  <>
		<MobileVideoHeader />
		<MobileSection2 camino={camino} {...props}/>
		<MobileSection3 />
		<Video360  {...props} />
	  </>
	);
  };


  const DesktopPage = (props) => {
	let camino = '..'
	//let poslang = '';
	if (props.pageContext.language === 'en') {
		camino = '../..';
		//poslang = "_en";
	}
	return (
	  <>
		<DesktopVideoHeader />
		<DesktopSection2 camino={camino} {...props}/>
		<DesktopSection3 />
		<Section id="seccion5" sectionClasses="py-0 text-center position-relative min-vh-100" childClasses={'bg-holder no-top'}>
			<iframe src={`https://terroir.laceliawines.com/tour/valledeuco.html?lang=${props.pageContext.language}`} title="Valle de UCO" className="w-100 h-100">
				<p>Your browser does not support iframes.</p>
			</iframe>
		</Section>
	  </>
	);
  };

const IndexPage = (props) => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		if (typeof window !== "undefined") {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
		}
  }, []);

  const isDesktop = !isMobile;

	useEffect(() => {
		var viewport = document.getElementsByTagName('body')[0];
		const checkPos = (e) => {
			var a = viewport.scrollTop;
			var b = viewport.scrollHeight - viewport.clientHeight;
			var c = a / b;
			console.log(c)
			if (c <= 0.3333333333333333 && c > 0 && document.querySelector("#imagen_01")) {
				document.querySelector("#imagen_01").classList.add("image_01_animate");
				document.querySelector("#imagen_02").classList.add("image_02_animate");
				document.querySelector("#imagen_03").classList.add("image_03_animate");
			}
			if (c === 0.4) {
				var dotMap = document.querySelectorAll(".dotMap");
				dotMap.forEach(function (userItem) {
					userItem.classList.add("dotanimate");
				});
			}
		}
		viewport.addEventListener('scroll', checkPos, false);
	}, [])

	return (
		<SimpleLocalize {...props}>
			<Layout page='index' {...props}>
				<Helmet>
					<body />
				</Helmet>
				{isDesktop ? <DesktopPage {...props} /> : <MobilePage {...props} />}
				{/*<section className="py-0 text-center position-relative min-vh-100">
					<CuartelesDeLaFinca {...props} />
				</section>*/}
			</Layout>
		</SimpleLocalize>)
}

export default IndexPage
