import React from 'react';
import { isMobile } from 'react-device-detect';

const Section = ({ id, sectionClasses, sectionStyle, childClasses, childStyle, children }) => {
    return (
        <section className={sectionClasses} id={id} style={sectionStyle}>
            <div className={childClasses} style={childStyle}>
                {children}
            </div>
        </section>
    );
};

export default Section;
