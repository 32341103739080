import React from "react"
import { FormattedMessage } from "react-intl";

const Section3 = () => {
  return (
    <>
      <h1 className="title-2 mt-4" style={{ maxWidth: "100%" }}>
        <FormattedMessage id="suelos-unicos" defaultMessage="suelos-unicos" />
      </h1>
      <hr />
      <p className="mt-4">
        <FormattedMessage
          id="finca-la-celia-se-encuentra-ubicada"
          defaultMessage="finca-la-celia-se-encuentra-ubicada"
        />
        <br />
        <FormattedMessage
          id="dando-como-resultado"
          defaultMessage="dando-como-resultado"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="suelo-tipo-paraje-altamira"
            defaultMessage="suelo-tipo-paraje-altamira"
          />
        </li>
        <li>
          <FormattedMessage
            id="suelo-tipo-la-Consulta"
            defaultMessage="suelo-tipo-la-Consulta"
          />
        </li>
        <li>
          <FormattedMessage
            id="suelo-tipo-eugenio-bustos"
            defaultMessage="suelo-tipo-eugenio-bustos"
          />
        </li>
      </ul>
      <p>
        <FormattedMessage
          id="datos-generales-de-nuestra-finca"
          defaultMessage="datos-generales-de-nuestra-finca"
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="tres-ochenta-hectareas-implantadas"
            defaultMessage="tres-ochenta-hectareas-implantadas"
          />
        </li>
        <li>
          <FormattedMessage
            id="cinco-cinco-Malbec"
            defaultMessage="cinco-cinco-Malbec"
          />
        </li>
        <li>
          <FormattedMessage
            id="trece-cabernet-sauvignon"
            defaultMessage="trece-cabernet-sauvignon"
          />
        </li>
        <li>
          <FormattedMessage
            id="trece-cabernet-franc"
            defaultMessage="trece-cabernet-franc"
          />
        </li>
        <li>
          <FormattedMessage
            id="altitud-nueve-ochenta"
            defaultMessage="altitud-nueve-ochenta"
          />
        </li>
        <li>
          <FormattedMessage
            id="riego-100-por-cien-presurizado"
            defaultMessage="riego-100-por-cien-presurizado"
          />
        </li>
        <li>
          <FormattedMessage
            id="edad-promedio-de-viniedos"
            defaultMessage="edad-promedio-de-viniedos"
          />
        </li>
      </ul>
    </>
  )
}

export default Section3
