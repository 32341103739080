import React, { useState } from "react"
import isologo from "../../../images/logo_la_celia.svg"

const modalStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100vh",
  background: "#000",
  display: "none",
  zIndex: "9000",
}

const bt360 = {
  backgroundRepeat: "no-repeat",
  width: "325px",
  backgroundSize: "contain",
  height: "60px",
  border: "none",
  position: "absolute",
  top: "-30px",
  left: "calc(50% - 162.5px)",
  backgroundColor: "transparent",
  fontStyle: "italic",
  paddingLeft: "40px",
}

const imgLogo = {
  height: "auto",
  maxWidth: "190px",
  padding: "12px 30px",
}

const closeModalIcon = {
  background: "none",
  border: 0,
  marginRight:'5px'
}

const Video360 = props => {
  const [modalVisible, setModalVisible] = useState(false)

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }
  return (
    <>
      <div
        id="modal"
        style={{
          ...modalStyle,
          display: modalVisible ? "block" : "none",
        }}
      >
        <header>
          <div className="row">
            <div className="col-6">
              <img
                src={isologo}
                className="img-fluid"
                alt="Logo"
                style={imgLogo}
              />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end">
              <button
                className="bt"
                onClick={toggleModal}
                style={closeModalIcon}
              >
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 53.6 54.4"
                  style={{
                    enableBackground: "new 0 0 53.6 54.4",
                    width: "30px",
                  }}
                >
                  <path
                    fill="#FFFFFF"
                    d="M31,27.4l6-6c0.8-0.8,0.8-2.1,0-2.8l-1.3-1.3c-0.8-0.8-2.1-0.8-2.8,0l-6,6L20.5,17c-0.8-0.8-2.1-0.8-2.8,0
	l-1.3,1.3c-0.8,0.8-0.8,2.1,0,2.8l6.3,6.3l-6,6c-0.8,0.8-0.8,2.1,0,2.8l1.3,1.3c0.8,0.8,2.1,0.8,2.8,0l6-6l5.6,5.6
	c0.8,0.8,2.1,0.8,2.8,0l1.3-1.3c0.8-0.8,0.8-2.1,0-2.8L31,27.4z"
                  />
                  <path
                    fill="#FFFFFF"
                    d="M26.7,1.4c-14.4,0-26,11.6-26,26c0,14.4,11.6,26,26,26c14.4,0,26-11.6,26-26C52.7,13.1,41.1,1.4,26.7,1.4z
	 M26.7,49.1C14.7,49.1,5,39.4,5,27.4c0-12,9.7-21.7,21.7-21.7s21.7,9.7,21.7,21.7C48.4,39.4,38.7,49.1,26.7,49.1z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </header>
        <div className="row" style={{ height: "calc(100vh - 74px)" }}>
          <div className="col-12">
            <iframe
              src={`https://terroir.laceliawines.com/tour/valledeuco.html?lang=${props.pageContext.language}`}
              title="Valle de UCO"
              className="w-100 h-100"
            >
              <p>Your browser does not support iframes.</p>
            </iframe>
          </div>
        </div>
      </div>
      <div className="position-relative" style={{ zIndex: 2 }}>
        <button className="bt-360" onClick={toggleModal} style={{...bt360,  backgroundImage: props.pageContext.language === 'en' ?  "url(../lafinca/back-bt-360.png)" :  "url(./lafinca/back-bt-360.png)"}}>
          Vista aérea 360° del Valle de Uco
        </button>
      </div>
    </>
  )
}

export default Video360
